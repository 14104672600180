<script>
import Layout from "../../layouts/course";
import { api } from "@/state/services";
import { VclCode } from "vue-content-loading";

export default {
    locales: {
    pt: {
      'Courses': 'Cursos',
      'There are no courses registered.': 'Não há cursos cadastrados.',
      'Material list': 'Lista de Materiais',
      'Start': 'Iniciar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'To view this course you need to be active in the month. to activate, click here.': '*Para visualizar este curso você precisa estar ativo no mês.  Para se ativar, clique aqui.',
      'An error occurred while performing the transaction': 'Ocorreu um erro ao realizar a transação',
    },
    es: {
      'Courses': 'Cursos',
      'There are no courses registered.': 'Não há cursos cadastrados.',
      'Material list': 'Lista de Materiais',
      'Start': 'Iniciar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'To view this course you need to be active in the month. to activate, click here.': '*Para visualizar este curso você precisa estar ativo no mês.  Para se ativar, clique aqui.',
      'An error occurred while performing the transaction': 'Ocurrió un error al realizar la transacción',
    }
  },
  components: { Layout, VclCode },
  data() {
    return {
      course: {
        status: false,
        loading: true,
        errored: false,

        id: this.$route.params.id,
        access: 'retrict',
        legacy: 'no',

        title: '',
        thumbnail: '',
        banner: '',
        video: '',
        description: '',
        tags: [],

        materials: '',
      }
    };
  },
  methods: {
    getCourse() {
      api
        .get('course/preview/' + this.course.id)
        .then(response => {
          if (response.data.status == 'success') {
            this.course.status      = true
            this.course.title       = response.data.course.title
            this.course.thumbnail   = response.data.course.thumbnail
            this.course.banner      = response.data.course.banner
            this.course.video       = response.data.course.video
            this.course.description = response.data.course.description
            this.course.tags        = response.data.course.tags
            this.course.access      = response.data.course.access
            this.course.legacy      = response.data.course.legacy

            this.course.loading = false

            this.getMaterials()
          } else if (response.data.status == 'restrict') {
            this.$router.push('/account/subscriptions');
          }
        })
        .catch(error => {
          this.course.errored = error
        })
    },
    getMaterials() {
      api
        .get('course/material/' + this.course.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.course.materials = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getCourse()
  },
};
</script>

<template>
  <Layout>
    <div v-if="course.tags.length > 0" class="mb-2">
      <router-link v-for="(tag, index) in course.tags" :key="index" tag="a" :to="'/course/category/' + tag.id" class="badge badge-soft-dark font-size-13 rounded-0 mr-2">
        {{ tag.name }}
      </router-link>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-24">{{ course.title }}</h4>
        </div>
      </div>
    </div>
    <div v-if="course.errored">
      <div class="card">
        <div class="card-body">
          {{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}
        </div>
      </div>
    </div>
    <div v-else-if="course.empty">
      <div class="card">
        <div class="card-body">
          {{ t('No records found.') }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="col-lg-6" v-if="course.loading">
        <VclCode/>
      </div>
      <div v-else>
        <div v-if="course.video.code">
          <div class="card">
            <div style="max-width:700px; margin:0 auto;">
              <iframe v-if="course.video.type == 'vimeo'" class="d-block m-auto" width="700" :src="'https://player.vimeo.com/video/' + course.video.code +'?color=ffffff'" style="max-width: 100%;" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
              <iframe v-else-if="course.video.type == 'youtube'" class="d-block m-auto" height="400" width="700" :src="'https://www.youtube.com/embed/'+course.video.code+''" frameborder="0"  style="max-width: 100%;"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="course.banner" class="card">
            <div style="max-width:700px; margin:0 auto;">
              <img :src="course.banner" alt="" class="w-100">
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div v-if="course.legacy == 'yes'" id="legacy">
              <div v-html="course.description"></div>
            </div>
            <div v-else v-html="course.description"></div>
          </div>
        </div>
        <div v-if="course.materials" class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ t('Material list') }}</h4>
            <ul class="list-unstyled">
              <li v-for="(material, index) in course.materials" :key="index">
                <div class="d-flex">
                  <input type="checkbox" class="mb-2" name="" id="">
                  <h5 class="font-size-14 ml-3"> {{ material.title }} </h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="course.legacy == 'no'" class="card text-center">
          <div class="card-body">
            <router-link class="btn btn-success btn-lg text-uppercase" :to="'/course/' + course.id">{{ t('Start') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#legacy {
  max-width: 700px !important;
  margin: 0 auto !important;
}
#legacy img {
  display: block;
  margin: 5px auto 15px;
  width: 100%;
  height: auto;
}
</style>